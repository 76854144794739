import React from 'react'
import { Button } from 'react-bootstrap'

import logo from '../assets/logo.png'

type PageNavProps = {
  handleOpenNeedHelp?: () => void
}

const PageNav: React.FunctionComponent<PageNavProps> = ({
  handleOpenNeedHelp,
}) => {
  return (
    <div className='navbar bg-dark py-4'>
      <div className='container d-flex'>
        <h3>
          <a href='/'>
            <img height='42px' src={logo} alt='logo' />
          </a>
        </h3>
        <a
          href='https://heatbee.co.uk/'
          className='ms-auto me-3 d-none d-md-block link-light text-white text-decoration-none'
        >
          Visit main site &gt;
        </a>
        <Button
          variant='primary'
          style={{ minWidth: 'auto' }}
          onClick={() => handleOpenNeedHelp && handleOpenNeedHelp()}
        >
          <span className='d-none d-md-inline'>Need Help?</span>
          <span className='d-md-none' style={{ fontSize: '24px' }}>
            ?
          </span>
        </Button>
      </div>
    </div>
  )
}

export default PageNav
