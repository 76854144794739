import React from 'react'

const FinanceDisclaimer = () => {
  return (
    <div
      className='p-3 text-center'
      style={{
        backgroundColor: '#FDDA00', // Primary color
        color: '#000000', // Secondary color
        fontSize: '0.875rem',
        fontWeight: '500',
        borderRadius: '4px',
      }}
    >
      Finance application subject to affordability, age, and status. Minimum
      spend applies.
    </div>
  )
}

export default FinanceDisclaimer
