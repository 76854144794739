import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { IoInformationCircleOutline } from 'react-icons/io5'

function HelpModal({ show, onHide }: { show: boolean; onHide: () => void }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className='d-flex justify-content-center'>
          <IoInformationCircleOutline
            style={{ fontSize: '70px', color: '#fdda00' }}
          />
        </div>
        <div className='d-flex flex-column align-items-center'>
          <p className='mt-3 fs-1 text-center' style={{ fontWeight: 'bold' }}>
            Need some help?
          </p>
          <p
            className='fs-6 text-center text-bold'
            style={{ fontWeight: 'bold' }}
          >
            If you have any issues or questions, our expert team is on hand to
            help. Please give us a call, send an email, or request a callback,
            and we will do our best to assist you.
          </p>

          <Button
            className='btn-primary border-0 px-3 py-2 rounded-5 text-bold mt-3'
            href='tel:03303 110 363'
            style={{ fontWeight: 'bold' }}
          >
            Call now
          </Button>
          <p className='mt-3 fs-5' style={{ fontWeight: 'bold' }}>
            03303 110 363
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default HelpModal
