import './footer.css'

import React, { useState } from 'react'
import { BsFacebook } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'

import Sidebar from './Sidebar'
const Footer = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [needHelp, setNeedHelp] = useState<boolean>(false)

  const handleOpenClose = () => {
    setOpen(!open)
    if (needHelp) {
      setNeedHelp(!needHelp)
    }
  }

  const handleOpenNeedHelp = () => {
    handleOpenClose()
    setNeedHelp(true)
  }
  return (
    // <div className='page-footer bg-dark py-4'>
    //   <Container>
    //     <img
    //       src={logo}
    //       alt='footer logo'
    //       height='54px'
    //       className='d-block mb-5'
    //     />
    //     <div className='footer-bottom d-flex justify-content-between text-white pt-5'>
    //       <p>Copyright © 2023 HeatBee.</p>
    //       <p>Privacy Policy</p>
    //     </div>
    //   </Container>
    // </div>
    <footer id='section-1199-17' className='ct-section c-bg-dark'>
      <div className='ct-section-inner-wrap'>
        <div id='div_block-1200-17' className='ct-div-block c-full-width'>
          <img
            id='image-1201-17'
            alt=''
            src='https://heatbee.co.uk/wp-content/uploads/2023/04/HB-Asset-4-V5.png'
            className='ct-image c-logo c-margin-bottom-m'
          />
        </div>
        <div
          id='div_block-1204-17'
          className='ct-div-block c-margin-bottom-m c-columns-2 c-columns-m-1 c-columns-gap-l'
        >
          <div id='div_block-1205-17' className='ct-div-block '>
            <div
              id='text_block-1206-17'
              className='ct-text-block c-text-light c-margin-bottom-l'
            >
              The efficient, hassle free way to buy a new boiler!
              <br />
            </div>
            <div id='div_block-1207-17' className='ct-div-block c-inline'>
              <a
                id='link-1208-17'
                className='ct-link'
                href='https://facebook.com/@goheatbee'
                target='_blank'
                rel='noreferrer'
              >
                <div
                  id='fancy_icon-1209-17'
                  className='ct-fancy-icon c-transition c-follow-light'
                >
                  <BsFacebook />
                </div>
              </a>
              <a
                id='link-1210-17'
                className='ct-link'
                href='https://www.instagram.com/heatbee.boilers/'
                target='_blank'
                rel='noreferrer'
              >
                <div
                  id='fancy_icon-1211-17'
                  className='ct-fancy-icon c-transition c-follow-light'
                >
                  <BsInstagram />
                </div>
              </a>
            </div>
          </div>
          <div id='div_block-1212-17' className='ct-div-block '>
            <div id='div_block-1213-17' className='ct-div-block'>
              <a
                id='link_text-1214-17'
                className='ct-link-text c-transition c-footer-link-light'
                href='https://heatbee.co.uk/terms-and-conditions/'
                target='_blank'
                rel='noreferrer'
              >
                Terms and Conditions
              </a>
              <a
                id='link_text-1215-17'
                className='ct-link-text c-transition c-footer-link-light'
                href='https://heatbee.co.uk/privacy-policy/'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
            </div>
            <img
              id='image-1217-17'
              alt=''
              src='https://heatbee.co.uk/wp-content/uploads/2023/06/gas-safe-2-2.png'
              className='ct-image'
            />
          </div>
        </div>
        <div id='new_columns-1218-17' className='ct-new-columns'>
          <div id='div_block-1219-17' className='ct-div-block'>
            <div
              id='text_block-1220-17'
              className='ct-text-block c-text-s c-text-light text-white'
            >
              Copyright © 2023 HeatBee Ltd
              <br />
            </div>
          </div>
          <div id='div_block-1223-17' className='ct-div-block c-columns-gap-s'>
            <img
              id='image-1224-17'
              alt=''
              src='https://heatbee.co.uk/wp-content/uploads/2023/07/Size48.png'
              className='ct-image payment-img'
              sizes='(max-width: 70px) 100vw, 70px'
            />
            <img
              id='image-1225-17'
              alt=''
              src='https://heatbee.co.uk/wp-content/uploads/2023/07/Size48-7.png'
              className='ct-image payment-img'
              sizes='(max-width: 70px) 100vw, 70px'
            />
            <img
              id='image-1226-17'
              alt=''
              src='https://heatbee.co.uk/wp-content/uploads/2023/07/Size48-4.png'
              className='ct-image payment-img'
              sizes='(max-width: 70px) 100vw, 70px'
            />
            <img
              id='image-1227-17'
              alt=''
              src='https://heatbee.co.uk/wp-content/uploads/2023/07/Size48-3.png'
              className='ct-image payment-img'
              sizes='(max-width: 70px) 100vw, 70px'
            />
            <img
              id='image-1228-17'
              alt=''
              src='https://heatbee.co.uk/wp-content/uploads/2023/07/Size48-5.png'
              className='ct-image payment-img'
              sizes='(max-width: 70px) 100vw, 70px'
            />
          </div>
          <div id='div_block-1229-17' className='ct-div-block'>
            <div
              id='link_button-1230-17'
              className='ct-link-button'
              onClick={() => handleOpenNeedHelp && handleOpenNeedHelp()}
            >
              Need help?
              <br />
            </div>
          </div>
        </div>
        <div id='div_block-1231-17' className='ct-div-block c-full-width'>
          <div id='text_block-1232-17' className='ct-text-block '>
            HeatBee Ltd is a registered company in England and Wales Company No.
            14846956. Our registered office is located in Crewe, Cheshire
            <br />
          </div>
        </div>
      </div>
      <Sidebar open={open} needHelp={needHelp} handleClose={handleOpenClose} />
    </footer>
  )
}

export default Footer
