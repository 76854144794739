import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useState, useEffect } from 'react'
import FinanceDisclaimer from './FinanceDisclaimer'
import { Accordion, Form, Button } from 'react-bootstrap'
import { getApiClient } from '../modules/axios'

const CheckoutForm = (props: any) => {
  const stripe = useStripe()
  const elements = useElements()

  const [agreed, setAgreed] = useState(false)
  const [agreedPartial, setAgreedPartial] = useState(false)
  const [finalPrice, setFinalPrice] = useState(0)
  const [error, setError] = useState(false)
  // Fetcprops.h clientSecret from the server when payment type changes
  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await getApiClient().post(
          `/checkout/update-payment-intent`,
          {
            orderId: props.orderId,
            paymentType: props.paymentType, // Send either 'full' or 'partial' to the server
          }
        )
        const data = await response.data
        props.setClientSecret(data.clientSecret)
        console.log(data)
        setFinalPrice(data.finalPrice)
        setError(false)
        if (data.clientSecret) {
          elements?.fetchUpdates()
        }
      } catch (error) {
        console.error('Error fetching client secret:', error)
        setError(true)
      }
    }

    fetchClientSecret()
  }, [props.paymentType])

  const handleAgreeChange = () => {
    setAgreed(!agreed)
  }
  const handlePartialAgreeChange = () => {
    setAgreedPartial(!agreedPartial)
  }

  const handlePaymentTypeChange = (type: 'full' | 'partial' | 'klarna') => {
    props.setPaymentType(type)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          process.env.REACT_APP_API_BASE_URL || 'https://api.heatbee.co.uk'
        }/checkout/success/${props.orderId}/${props.paymentType}`,
      },
    })

    if (result.error) {
      console.log(result.error.message)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Accordion defaultActiveKey='0'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header onClick={() => handlePaymentTypeChange('full')}>
            Pay in Full
          </Accordion.Header>
          <Accordion.Body>
            {props.paymentType === 'full' && props.clientSecret && (
              <PaymentElement />
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='1'>
          <Accordion.Header onClick={() => handlePaymentTypeChange('partial')}>
            Pay A Deposit
          </Accordion.Header>
          <Accordion.Body>
            {props.paymentType === 'partial' && props.clientSecret && (
              <PaymentElement />
            )}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='2'>
          <Accordion.Header onClick={() => handlePaymentTypeChange('klarna')}>
            Pay With Klarna
          </Accordion.Header>
          <Accordion.Body>
            {props.paymentType === 'klarna' && props.clientSecret && !error && (
              <PaymentElement />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {props.paymentType === 'partial' && (
        <Form.Group controlId='formBasicCheckbox2' className='mt-3'>
          <Form.Check
            type='checkbox'
            label='I confirm that outstanding payment will be due on the day of installation'
            checked={agreedPartial}
            onChange={handlePartialAgreeChange}
          />
        </Form.Group>
      )}

      <Form.Group controlId='formBasicCheckbox' className='mt-3'>
        <Form.Check
          type='checkbox'
          label='I agree to the terms and conditions'
          checked={agreed}
          onChange={handleAgreeChange}
        />
      </Form.Group>

      <Button
        type='submit'
        className='btn btn-primary mt-3'
        disabled={
          !stripe ||
          !props.clientSecret ||
          !agreed ||
          (props.paymentType === 'partial' ? !agreedPartial : false)
        }
      >
        Submit Payment (£ {finalPrice || props.finalPrice})
      </Button>
      <div className='mt-4'>
        <FinanceDisclaimer />
      </div>
      <div className='mt-4'>
        <a
          href='https://ideal4finance.com/heatbee?utm_source=3rd&utm_medium=banner&utm_campaign=Banners'
          target='_blank'
          title='Ideal4Finance'
          rel='noreferrer'
        >
          <img
            className='img-fluid'
            src='https://ideal4finance.com/img/web-banners/finance_available/yellow-horizontal.png'
            alt='Ideal4Finance'
          />
        </a>
      </div>
    </form>
  )
}

export default CheckoutForm
