import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'

import logo from '../assets/logo.png'

type PageNavProps = {
  handleOpenNeedHelp?: () => void
}

const PageNav: React.FunctionComponent<PageNavProps> = ({
  handleOpenNeedHelp,
}) => {
  useEffect(() => {
    // Facebook Pixel Code
    const fbScript = document.createElement('script')
    fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1636623713895093');
      fbq('track', 'PageView');
    `
    document.head.appendChild(fbScript)

    // Facebook Pixel NoScript
    const fbNoScript = document.createElement('noscript')
    fbNoScript.innerHTML = `<img height="1" width="1" src="https://www.facebook.com/tr?id=1636623713895093&ev=PageView&noscript=1" alt="Facebook Pixel"/>`
    document.head.appendChild(fbNoScript)

    // Google Tag Manager
    const gtagScript = document.createElement('script')
    gtagScript.async = true
    gtagScript.src =
      'https://www.googletagmanager.com/gtag/js?id=AW-11565811270'
    document.head.appendChild(gtagScript)

    const gtagInlineScript = document.createElement('script')
    gtagInlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-11565811270');
    `
    document.head.appendChild(gtagInlineScript)
  }, []) // Runs only on mount

  return (
    <div className='navbar bg-dark py-4'>
      <div className='container d-flex'>
        <h3>
          <a href='/'>
            <img height='42px' src={logo} alt='logo' />
          </a>
        </h3>
        <a
          href='https://heatbee.co.uk/'
          className='ms-auto me-3 d-none d-md-block link-light text-white text-decoration-none'
        >
          Visit main site &gt;
        </a>
        <Button
          variant='primary'
          style={{ minWidth: 'auto' }}
          onClick={() => handleOpenNeedHelp && handleOpenNeedHelp()}
        >
          <span className='d-none d-md-inline'>Need Help?</span>
          <span className='d-md-none' style={{ fontSize: '24px' }}>
            ?
          </span>
        </Button>
      </div>
    </div>
  )
}

export default PageNav
